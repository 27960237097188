// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-page-components-content-page-tsx": () => import("./../../../src/page-components/ContentPage.tsx" /* webpackChunkName: "component---src-page-components-content-page-tsx" */),
  "component---src-page-components-ipfs-page-tsx": () => import("./../../../src/page-components/IpfsPage.tsx" /* webpackChunkName: "component---src-page-components-ipfs-page-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-sequence-slug-tsx": () => import("./../../../src/pages/{Sequence.slug}.tsx" /* webpackChunkName: "component---src-pages-sequence-slug-tsx" */),
  "component---src-pages-tokens-token-slug-tsx": () => import("./../../../src/pages/tokens/{Token.slug}.tsx" /* webpackChunkName: "component---src-pages-tokens-token-slug-tsx" */)
}

